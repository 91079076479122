import {
  SectionTitleComponent,
} from "../../styles/CommonComponents";
import Achievement from "./Achievement";
import {
  AchievementsComponent,
  ContainerComponent,
  SectionSubtitleComponent,
  SpacerComponent,
  TitleContainerComponent,
} from "./AchievementComponents";

const Achievements = () => {
  return (
    <ContainerComponent>
      <TitleContainerComponent data-aos="fade-down" >
        <SectionTitleComponent>Achievements</SectionTitleComponent>

        <SectionSubtitleComponent>
          We offer software development infused solutions to varied industries
          making sure it aligns with your system, needs and aids in scaling
          business.
        </SectionSubtitleComponent>
      </TitleContainerComponent>

      <AchievementsComponent>
        <Achievement title="10+" subtitle="Years Of Trust" />
        <SpacerComponent />
        <Achievement title="300+" subtitle="Successful Projects" />
        <SpacerComponent />
        <Achievement title="30+" subtitle="Professionals" />
        <SpacerComponent />
        <Achievement title="7+" subtitle="Global location" />
      </AchievementsComponent>
    </ContainerComponent>
  );
};

export default Achievements;
