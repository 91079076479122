import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";
import { screenSize } from "../../styles/screenSize";

export const ContainerComponent = styled.section`
  display: flex;
  margin-top: 140px;
  gap: 80px;
  justify-content: space-between;

  @media (max-width: ${screenSize.laptop}) {
    margin-top: 80px;
  }
`;

export const SectionContainerComponent = styled.div`
  display: flex;
  width: 640px;
  align-items: baseline;
  justify-content: flex-end;
  gap: 24px;
  flex-direction: column;

  @media (max-width: ${screenSize.laptop}) {
    flex: 1;
    align-items: normal;
    justify-content: auto;
  }
`;

export const TitleComponent = styled.p`
  color: ${colors.neutral800};
  font-family: "IBM Plex Sans";
  font-size: 128px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  margin: 0;
  padding: 0;

  @media (max-width: ${screenSize.laptop}) {
    font-size: 96px;
  }
`;

export const SubtitleComponent = styled.p`
  color: ${colors.neutral500};
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  padding: 0;

  @media (max-width: ${screenSize.laptop}) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ColoredSubtitleComponent = styled(SubtitleComponent)`
  color: ${colors.neutral800};
  display: inline;
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const ImageContainer = styled.div`
  animation: ${rotation} 40s linear infinite;

  @media (max-width: ${screenSize.laptop}) {
    display: none;
  }
`;
