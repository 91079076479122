import styled from "styled-components";
import { colors } from "./colors";
import { screenSize } from "./screenSize";
import backgroundImage from "../assets/images/backgroundImage.jpg";

export const MainSection = styled.div<{ $backgroundColor?: string }>`
  background-color: ${(props) => props.$backgroundColor};
`;

export const MainContainer = styled.div`
  width: 1360px;
  margin: 0 auto;

  @media (max-width: ${screenSize.desktop}) {
    max-width: 896px;
  }

  @media (max-width: ${screenSize.laptop}) {
    max-width: 887px;
  }

  @media (max-width: ${screenSize.tablet}) {
    max-width: 678px;
  }

  @media (max-width: ${screenSize.mobile}) {
    max-width: 343px;
  }
`;

export const ImageComponent = styled.div`
  margin-top: 80px;
  background-size: cover;
  border-radius: 10px;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  width: 100%;
  height: 582px;

  @media (max-width: ${screenSize.desktop}) {
    height: 383px;
  }

  @media (max-width: ${screenSize.laptop}) {
    margin-top: 48px;
  }

  @media (max-width: ${screenSize.tablet}) {
    height: 290px;
  }

  @media (max-width: ${screenSize.mobile}) {
    max-width: 343px;
    height: 343px;
  }
`;

export const SectionTitleComponent = styled.p`
  color: ${colors.neutral800};
  font-family: "IBM Plex Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin: 0;
  padding: 0;

  @media (max-width: ${screenSize.laptop}) {
    font-size: 40px;
    line-height: 36px;
  }
`;

export const SectionSubtitleComponent = styled.p`
  color: ${colors.neutral500};
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 0;

  @media (max-width: ${screenSize.laptop}) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ColoredSectionSubtitleComponent = styled(SectionSubtitleComponent)`
  color: ${colors.neutral800};
  display: inline;
`;

export const NavComponent = styled.nav<{ $isShown?: boolean }>`
  display: flex;

  @media (max-width: ${screenSize.mobile}) {
    display: ${(props) => props.$isShown ? "flex" : "none"}
  }
`;

export const UlComponent = styled.ul<{
  $direction?: "column" | "row";
  $gap?: string;
}>`
  display: flex;
  flex-direction: ${(props) => props.$direction};
  list-style: none;
  padding: 0;
  gap: ${(props) => (props.$gap ? props.$gap : "32px")};
  margin: 0;
  align-items: center;

  @media (max-width: ${screenSize.mobile}) {
    align-items: flex-start;
  }
`;

export const LiComponent = styled.li`
  margin: 0;
  text-indent: 0;
  list-style-type: 0;

  &:hover {
    opacity: 0.7;
  }
`;

export const NavLink = styled.a`
  text-decoration: none;
  color: ${colors.neutral800};
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
`;
