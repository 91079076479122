import {
  SectionSubtitleComponent,
  SectionTitleComponent,
} from "../../styles/CommonComponents";
import Form from "../formSection/Form";
import {
  ContactsContainerComponents,
  TitleContainerComponent,
} from "./ContactsComponents";

const Contacts = () => {
  return (
    <ContactsContainerComponents id="contact-section">
      <TitleContainerComponent data-aos="fade-down" >
        <SectionTitleComponent>Get in touch</SectionTitleComponent>

        <SectionSubtitleComponent>
          We’re ready to become your personal guide through the software
          experience as we bring your app or website to life.
        </SectionSubtitleComponent>
      </TitleContainerComponent>

      <Form />
    </ContactsContainerComponents>
  );
};

export default Contacts;
