import { MainContainer, MainSection } from "../styles/CommonComponents";
import Experience from "../components/experienceSection/Experience";

const ExperienceSection = () => {
  return (
    <MainSection>
      <MainContainer>
        <Experience />
      </MainContainer>
    </MainSection>
  );
};

export default ExperienceSection;
