import { SectionTitleComponent } from "../../styles/CommonComponents";
import SolutionCard from "./SolutionCard";
import {
  CardsContainerComponent,
  ContainerComponent,
} from "./SolutionComponents";
import { ReactComponent as FinanceIcon } from "../../assets/icons/finance.svg";
import { ReactComponent as BrandingIcon } from "../../assets/icons/branding.svg";
import { ReactComponent as MarketingIcon } from "../../assets/icons/marketing.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { ReactComponent as MobileIcon } from "../../assets/icons/mobile.svg";
import { ReactComponent as WebIcon } from "../../assets/icons/web.svg";

const solutionCardsParamsList = [
  {
    key: "branding-strategy",
    icon: <BrandingIcon />,
    title: "Branding & Strategy",
    subtitle:
      "Developing a unique brand, including name, logo, visual style, as well as promotion strategy and integration of the brand into your digital products.",
  },
  {
    key: "marketing",
    icon: <MarketingIcon />,
    title: "Marketing",
    subtitle:
      "Developing digital marketing, content marketing, and SEO strategies, running advertising campaigns, and analyzing results for your business growth.",
  },
  {
    key: "ux-ui",
    icon: <UserIcon />,
    title: "UX/UI",
    subtitle:
      "Creating intuitive interfaces and optimal user experiences, improving product interaction to enhance customer satisfaction and loyalty.",
  },
  {
    key: "mobile",
    icon: <MobileIcon />,
    title: "Mobile",
    subtitle:
      "Developing innovative mobile applications for iOS and Android, from idea to market launch, with a focus on user experience and functionality.",
  },
  {
    key: "web",
    icon: <WebIcon />,
    title: "Web",
    subtitle:
      "Developing high-quality and functional websites and web applications, responsive across all devices, using cutting-edge technologies and best practices.",
  },
  {
    key: "finance-legal",
    icon: <FinanceIcon />,
    title: "Finance & Legal",
    subtitle:
      "Financial consultations, legal protection, contract and license drafting, ensuring legal security for your business.",
  },
];

const Solution = () => {
  return (
    <ContainerComponent id="solution-section">
      <SectionTitleComponent data-aos="fade-down" >Our Solution</SectionTitleComponent>

      <CardsContainerComponent>
        {solutionCardsParamsList.map((card) => (
          <SolutionCard {...card} />
        ))}
      </CardsContainerComponent>
    </ContainerComponent>
  );
};

export default Solution;
