import styled from "styled-components";
import { colors } from "../../styles/colors";
import { screenSize } from "../../styles/screenSize";

export const ContainerComponent = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  padding-bottom: 180px;

  @media (max-width: ${screenSize.laptop}) {
    margin-top: 80px;
    padding-bottom: 120px;
  }
`;

export const TitleContainerComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 408px;

  @media (max-width: ${screenSize.laptop}) {
    width: 100%;
  }
`;

export const AchievementsComponent = styled.div`
  margin-top: 64px;
  display: flex;
  height: 134px;
  gap: 40px;

  @media (max-width: ${screenSize.mobile}) {
    flex-direction: column;
    height: auto;
    gap: 32px;
  }
`;

export const SpacerComponent = styled.div`
  width: 1px;
  height: auto;
  background-color: ${colors.neutral800};

  @media (max-width: ${screenSize.mobile}) {
    width: 100%;
    height: 1px;
  }
`;

export const AchievementComponent = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 280px;
  flex-direction: column;
`;

export const AchievementTitleComponent = styled.p`
  color: ${colors.neutral800};
  font-family: "IBM Plex Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  margin: 0;
  padding: 0;

  @media (max-width: ${screenSize.laptop}) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.2px;
  }
`;

export const AchievementSubtitleComponent = styled.p`
  color: ${colors.neutral800};
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 0;

  @media (max-width: ${screenSize.laptop}) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const SectionSubtitleComponent = styled.p`
  color: ${colors.neutral500};
  font-family: Nunito;
  width: 640px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 0;

  @media (max-width: ${screenSize.desktop}) {
    width: 535px;
  }
  
  @media (max-width: ${screenSize.laptop}) {
    font-size: 16px;
    width: 408px;
    line-height: 22px;
  }

  @media (max-width: ${screenSize.tablet}) {
    width: 100%;
  }
`;
