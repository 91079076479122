import { ChangeEvent, FormEvent, useState } from "react";
import Button from "../ui/button/Button";
import {
  ButtonContainerComponent,
  FieldComponent,
  FormComponent,
} from "./FormComponents";

const _env = window.config || process.env;

const Form = () => {
  const [formData, setFormData] = useState({
    company: "",
    email: "",
    info: "",
    additionalInfo: "",
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const requestData = {
        message: [
          `Company name:\n ${formData.company}`,
          `Another way of communication:\n ${formData.info}`,
          `How did they found us:\n ${formData.additionalInfo}`,
        ].join("\n\n"),
        replyTo: formData.email,
        subject: "ZIMA feedback",
      };

      const response = await fetch(
        `${_env.REACT_APP_API_BASE_URL}/feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setFormData({
        company: "",
        email: "",
        info: "",
        additionalInfo: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <FormComponent onSubmit={handleSubmit}>
      <FieldComponent
        required
        type="text"
        name="company"
        placeholder="Company"
        value={formData.company}
        onChange={handleChange}
      />
      <FieldComponent
        required
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
      />
      <FieldComponent
        required
        type="text"
        name="info"
        placeholder="Another way of communication"
        value={formData.info}
        onChange={handleChange}
      />
      <FieldComponent
        required
        type="text"
        name="additionalInfo"
        placeholder="How did they find out about us"
        value={formData.additionalInfo}
        onChange={handleChange}
      />
      <ButtonContainerComponent>
        <Button $padding="12px 28px" type="submit">Send</Button>
      </ButtonContainerComponent>
    </FormComponent>
  );
};

export default Form;
