import styled from "styled-components";

export const HeaderComponent = styled.header`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  padding: 20px 0;
`;
