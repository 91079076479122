import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const ButtonComponent = styled.button<{ $padding?: string }>`
  width: 100%;
  display: flex;
  height: 32px;
  padding: ${(props) => (props.$padding ? props.$padding : "7px 12px")};
  justify-content: center;
  align-items: center;
  font-size: 16px;
  gap: 4px;
  border-radius: 200px;
  border: none;
  background: ${colors.primary300};
  color: ${colors.neutral};

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
