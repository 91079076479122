import styled from "styled-components";
import { screenSize } from "../../styles/screenSize";

export const ContactsContainerComponents = styled.div`
  display: flex;
  gap: 80px;
  padding: 180px 0 120px;

  @media (max-width: ${screenSize.laptop}) {
    flex-direction: column;
    padding: 120px 0 80px;
    gap: 32px;
  }
`;

export const TitleContainerComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 640px;

  @media (max-width: ${screenSize.laptop}) {
    gap: 24px;
    width: 100%;
  }
`;
