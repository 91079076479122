import { MainContainer, MainSection } from "../styles/CommonComponents";
import Solution from "../components/solutionSection/Solution";

const SolutionSection = () => {
  return (
    <MainSection>
      <MainContainer>
        <Solution />
      </MainContainer>
    </MainSection>
  );
};

export default SolutionSection;
