import Header from "../components/header/Header";
import Title from "../components/titleSection/Title";
import {
  ImageComponent,
  MainContainer,
  MainSection,
} from "../styles/CommonComponents";
import { colors } from "../styles/colors";
import Achievements from "../components/achievementsSection/Achievements";

const HeaderSection = () => {
  return (
    <MainSection $backgroundColor={colors.background}>
      <MainContainer>
        <Header />
        <Title />
        <ImageComponent />
        <Achievements />
      </MainContainer>
    </MainSection>
  );
};

export default HeaderSection;
