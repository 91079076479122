import Contacts from "../components/contactSection/Contacts";
import Footer from "../components/footer/Footer";
import { MainContainer, MainSection } from "../styles/CommonComponents";
import { colors } from "../styles/colors";

const FooterSection = () => {
  return (
    <MainSection $backgroundColor={colors.background}>
      <MainContainer>
        <Contacts />
        <Footer />
      </MainContainer>
    </MainSection>
  );
};

export default FooterSection;
