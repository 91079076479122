import { useEffect } from "react";
import ExperienceSection from "./sections/ExperienceSection";
import FooterSection from "./sections/FooterSection";
import HeaderSection from "./sections/HeaderSection";
import SolutionSection from "./sections/SolutionSection";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <HeaderSection />
      <SolutionSection />
      <ExperienceSection />
      <FooterSection />
    </>
  );
};

export default App;
