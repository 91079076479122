import styled from "styled-components";
import { colors } from "../../styles/colors";
import { screenSize } from "../../styles/screenSize";

export const FormComponent = styled.form`
  display: flex;
  width: 640px;
  align-items: baseline;
  justify-content: flex-end;
  gap: 24px;
  flex-direction: column;

  @media (max-width: ${screenSize.laptop}) {
    width: 100%;
    align-items: normal;
    justify-content: auto;
  }
`;

export const FieldComponent = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid ${colors.neutral500};
  padding: 10px 0;
  background-color: ${colors.background};

  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.primary300};
  }

  &::placeholder {
    color: ${colors.neutral500};
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: ${screenSize.laptop}) {
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0.5px;
    }
  }
`;

export const ButtonContainerComponent = styled.div``;
