import {
  ColoredSectionSubtitleComponent,
  SectionSubtitleComponent,
} from "../../styles/CommonComponents";
import {
  ColoredTitleComponent,
  ContainerComponent,
  ContentContainerComponent,
  ImageComponent,
  SubtitleContainerComponent,
  TitleComponent,
  TitleContainerComponent,
} from "./ExperienceComponents";

const Experience = () => {
  return (
    <ContainerComponent id="experience-section">
      <TitleContainerComponent data-aos="fade-down" >
        <TitleComponent>
          We make mobile apps and custom software, but we’re not just in the
          development services business.&nbsp;
          <ColoredTitleComponent>
            We’re in the user experience business.
          </ColoredTitleComponent>
        </TitleComponent>
      </TitleContainerComponent>

      <ContentContainerComponent>
        <SubtitleContainerComponent data-aos="fade-down" >
          <SectionSubtitleComponent>
            The applications we build are the means to an end.&nbsp;
            <ColoredSectionSubtitleComponent>
              A delightful user experience is the real product.
            </ColoredSectionSubtitleComponent>
          </SectionSubtitleComponent>
          <SectionSubtitleComponent>
            We’ve been obsessed with developing software applications for more
            than a decade. Here’s the short version of the story.
          </SectionSubtitleComponent>
        </SubtitleContainerComponent>

        <ImageComponent />
      </ContentContainerComponent>
    </ContainerComponent>
  );
};

export default Experience;
