import styled from "styled-components";
import { colors } from "../../styles/colors";
import { screenSize } from "../../styles/screenSize";

export const ContainerComponent = styled.section`
  display: flex;
  flex-direction: column;
  padding: 180px 0 120px;

  @media (max-width: ${screenSize.laptop}) {
    padding: 120px 0 80px;
  }
`;

export const CardsContainerComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: space-between;
  margin-top: 96px;

  @media (max-width: ${screenSize.laptop}) {
    gap: 64px;
    margin-top: 48px;
  }

  @media (max-width: ${screenSize.mobile}) {
    flex-direction: column;
  }
`;

export const CardComponent = styled.div`
  display: flex;
  flex: 30%;
  flex-direction: column;
`;

export const CardTitleComponent = styled.p`
  color: ${colors.neutral800};
  font-family: "IBM Plex Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin: 24px 0 16px;
  padding: 0;

  @media (max-width: ${screenSize.laptop}) {
    margin: 16px 0 8px;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.15px;
  }
`;
