import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { screenSize } from "../../../styles/screenSize";
import { NavLink } from "../../../styles/CommonComponents";

export const MenuButtonComponent = styled.div<{ $isOpen: boolean }>`
  width: 30px;
  height: 30px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;

  div {
    width: 30px;
    height: 2px;
    background-color: ${colors.neutral800};
    border-radius: 10px;
    transition: all 0.3s linear;
    transform-origin: 1px;

    &:first-child {
      transform: ${({ $isOpen }) => ($isOpen ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      opacity: ${({ $isOpen }) => ($isOpen ? "0" : "1")};
      transform: ${({ $isOpen }) => $isOpen ? "translateX(20px)" : "translateX(0)"};
    }

    &:nth-child(3) {
      transform: ${({ $isOpen }) => ($isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }

  @media (min-width: ${screenSize.mobile}) {
    display: none;
  }
`;

export const BarComponent = styled.div``;

export const MenuComponent = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 110px 0 40px;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ $isOpen }) => ($isOpen ? "translateY(0)" : "translateY(-100%)")};
  z-index: 10;

  button {
    width: 327px;
  }

  @media (min-width: ${screenSize.mobile}) {
    display: none;
  }
`;

export const NavComponent = styled.nav`
  display: flex;
`;

export const UlComponent = styled.ul<{ $gap?: string;}>`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  gap: ${(props) => (props.$gap ? props.$gap : "32px")};
  margin: 0;
  align-items: center;
`;

export const NavLinkComponent = styled(NavLink)`
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 1px;
`;
