import { useEffect, useState } from "react";
import {
  BarComponent,
  MenuButtonComponent,
  MenuComponent,
  NavComponent,
  NavLinkComponent,
  UlComponent,
} from "./BurgerMenuComponents";
import { LiComponent, NavLink } from "../../../styles/CommonComponents";
import Button from "../button/Button";

const BurgerMenu = () => {
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const toggleMenu = () => setMenuVisibility((prev) => !prev);

  useEffect(() => {
    if (isMenuVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuVisible]);

  return (
    <>
      <MenuButtonComponent $isOpen={isMenuVisible} onClick={toggleMenu}>
        <BarComponent />
        <BarComponent />
        <BarComponent />
      </MenuButtonComponent>
      <MenuComponent $isOpen={isMenuVisible}>
        <NavComponent>
          <UlComponent>
            <LiComponent>
              <NavLinkComponent href="#solution-section" onClick={toggleMenu}>
                Solution
              </NavLinkComponent>
            </LiComponent>
            <LiComponent>
              <NavLinkComponent href="#experience-section" onClick={toggleMenu}>
                Experience
              </NavLinkComponent>
            </LiComponent>
            <LiComponent>
              <NavLinkComponent href="#contact-section" onClick={toggleMenu}>
                Contacts
              </NavLinkComponent>
            </LiComponent>
          </UlComponent>
        </NavComponent>

        <NavLink href="#contact-section" onClick={toggleMenu}>
          <Button>Get in touch</Button>
        </NavLink>
      </MenuComponent>
    </>
  );
};

export default BurgerMenu;
