import styled from "styled-components";
import { screenSize } from "../../styles/screenSize";

export const FooterContainerComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 60px;

  @media (max-width: ${screenSize.laptop}) {
    padding-bottom: 40px;
  }
  
  @media (max-width: ${screenSize.mobile}) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 32px;
  }
`;

export const InfoComponentContainer = styled.div`
  display: flex;
  gap: 80px;
  align-items: flex-end;

  @media (max-width: ${screenSize.mobile}) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 32px;
  }
`;

export const InfoComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AddressComponent = styled(InfoComponent)`
  gap: 0;
`;
